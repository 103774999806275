.brand {
    width: 230px;
    height:149px;
}
.up {
    position: relative;
    top: -40em;
    z-index: 999;
}
#change_password {
    position: relative;
    right: 10px;
}
.input-group-text {
    background-color: #F5F5F5;
    border: none;
}
.card {
    background: rgba(255, 255, 255, 0.98);
}